import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ChildrenOutletContexts, RouterModule } from '@angular/router';
import { BaseComponent } from '../../shared';
import { fadeInOutRouteAnimation } from '../../shared/animations/fade-in-out-route-animation';
import { AppNavBarComponent } from '../app-nav-bar';
import { AppSidenavComponent } from '../app-sidenav';
import { AppLayoutService } from './app-layout.service';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'layout',
	templateUrl: './app-layout.component.html',
	styleUrl: './app-layout.component.scss',
	imports: [
		RouterModule,
		CommonModule,
		AppNavBarComponent,
		AppSidenavComponent,
		MatSidenavModule,
		MatListModule,
		MatIconModule
	],
	animations: [fadeInOutRouteAnimation]
})
export class AppLayoutComponent extends BaseComponent {
	public opened: boolean = false;

	constructor(
		public readonly appLayoutService: AppLayoutService,
		private readonly _contexts: ChildrenOutletContexts
	) {
		super();

		this.opened = appLayoutService.openByDefault();
	}

	public getRouteAnimationData(): unknown {
		return this._contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
	}
}
