import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationService } from '../layout';
import { BaseService } from '../shared';

@Injectable({
	providedIn: 'root'
})
export class SandboxService extends BaseService<void> {
	constructor(_httpClient: HttpClient, _notificationService: NotificationService) {
		super('sandbox', _httpClient, _notificationService);
	}

	public generateDocument(): Observable<HttpResponse<Blob>> {
		return this._httpClient.get('generate-document', { observe: 'response', responseType: 'blob' });
	}
}
