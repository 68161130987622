<sidenav>
	<app-nav-bar (quickActionsOpenedState)="opened = $event" [quickActionsOpened]="opened"></app-nav-bar>
	<mat-drawer-container>
		<mat-drawer mode="side" [opened]="opened">
			<mat-nav-list dense>
				@for (section of appLayoutService.navigationLinkSections(); track section) {
					@if (section.sectionHeader) {
						<h4 matSubheader>{{ section.sectionHeader }}</h4>
					}

					@for (navigationLink of section.navigationLinks; track navigationLink) {
						<mat-list-item
							[routerLink]="navigationLink.routerLink"
							[ngClass]="{ 'line-break-list-item': navigationLink.name.length > 18 }">
							<mat-icon aria-hidden="false" matListItemIcon>{{ navigationLink.icon }}</mat-icon>
							<span matListItemTitle>{{ navigationLink.name }}</span>
						</mat-list-item>
					}
				}
			</mat-nav-list>
		</mat-drawer>
		<mat-drawer-content>
			<div [@fadeInOutRouteAnimations]="getRouteAnimationData()" style="display: contents">
				<router-outlet class="router-outlet-fixed" />
			</div>
		</mat-drawer-content>
	</mat-drawer-container>
</sidenav>
